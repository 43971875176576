import React from 'react';

export default function NotFound() {
  return (
    <div className="container">
      <div className="not-found d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh' }}>
    <h3 className="text-center">Page Not Found</h3>
</div>


    </div>
  );
}
