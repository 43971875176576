import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';


const QRCodeGenerator = ({ transactionId }) => {
 
  const qrValue = `${transactionId}`; // Customize the URL as needed

  return (
    <div>
      {/* <h3 className='mb-3'>Scan the QR Code</h3> */}
      <QRCodeCanvas value={qrValue} size={256} />
    </div>
  );
};

export default QRCodeGenerator;
