import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { base_url, aws } from "../../../environment/environment.tsx";
import './home.css';
import QRCodeGenerator from '../qr/qr.tsx';

const Home = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [data, setData] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true); // Loading state

    const handleConvert = async () => {
        setLoading(true); // Set loading to true when API call starts
        try {
            const response = await axios.post(`${base_url}events/qr-view/`, {
                id: id,
            });
            if (response?.data?.status === 100) {
                setData(response.data.data);
                setError(null);
            } else if (response?.data?.status === 101 || response?.data?.status === 102) {
                setError(response.data.message);
            } else {
                setError(response.data.message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "An unknown error occurred";
            setError(errorMessage);
        } finally {
            setLoading(false); // Set loading to false when API call completes
        }
    };

    useEffect(() => {
        handleConvert();
    }, []);

    const formatDate = (dateString: string) => {
        if (!dateString) return 'Invalid date';

        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Invalid date';
        }

        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }).format(date);
    };

    return (
        <>
            {loading ? ( // Show loading spinner or message while loading
                <div className="loading-container">
                    <div className="spinner"></div> {/* CSS Spinner */}
                    <p>Loading...</p>
                </div>
            ) : error ? (
                <div className="error-container">
                    <p className="error-message text-danger">{error}</p>
                </div>
            ) : (
                <>
                    <section className='sec_3'>
                        <div className='home_main container'>
                            <div className='row align-items-center'>
                                <div className='col-sm-12 col-md-6 col-lg-6 sec_3_right'>
                                    <div className="banner-wrapper mb-1">
                                        <div
                                            className="banner"
                                            style={{
                                                backgroundImage: `url(${aws}${data?.event_banner})`,
                                            }}
                                        ></div>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                    <div className='mt-3 mb-3'>
                                        <p className='qr_p2'>TICKET ID</p>
                                        <p className='qr_p3'>#{data?.transaction_id}</p>
                                        <QRCodeGenerator transactionId={`${data?.event_details}.${data?.transaction_id}`} />
                                        <p className='qr_p5 mt-3'>{data?.customer_first_name} {data?.customer_last_name}</p>
                                        <p className='qr_p6'>Booking Date: {formatDate(data?.request_datetime)}</p>
                                        <p className='qr_p7 mt-3'>PLEASE PRODUCE THIS QR AT THE GATE</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div style={{ width: '100%' }} className='mt-auto text-center footer_org'>
                        <p className='footer_t1 mt-3'>BY PURCHASING THIS TICKET, I AGREE TO THE "TERMS AND CONDITIONS"</p>
                        <p className='footer_t2'>ALL RIGHTS RESERVED - ONETICKET EVENT 2024</p>
                        <hr className='hr_padding' />
                        <p className='text-center copy-right mt-2'>© 2024 Copyright by Spemai. All rights reserved.</p>
                    </div>
                </>
            )}
        </>
    );
};

export default Home;
