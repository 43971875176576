import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './pages/content/home/home.tsx'
import NotFound from './pages/content/notfound/notfound.tsx'

function App() {
  return (
    <div className="App">
       <Router>
        <Routes>
        <Route path="/" element={<NotFound />} />
          <Route path="/:id" element={<Home />} />
        </Routes>
    
    </Router>
     
    </div>
  );
}

export default App;
